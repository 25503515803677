import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
import AboutPage from './AboutPage'
import Metadata from '../../constants/metadata'

class Index extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>{Metadata.About.pageTitle}</title>
          <meta name="title" content={Metadata.About.metaTitle} />
          <meta name="description" content={Metadata.About.metaDescription} />
        </Helmet>
        <Layout
          isHome={false}
          sections={Metadata.Navigation.Links}
        >
          <AboutPage />
        </Layout>
      </div>
    )
  }
}

export default Index

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`